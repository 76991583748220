import React from "react";
import { ReactComponent as EmptyStar } from "@/assets/images/EmptyStar.svg"
import { ReactComponent as HalfStar } from "@/assets/images/HalfStar.svg"
import { ReactComponent as FullStar } from "@/assets/images/FullStar.svg"
import { Box, Center, Flex } from "@chakra-ui/react";

interface IProp {
    score: number,
    sumStar?: number,
    fillColor?: string,
    strokeColor?: string,
}

const StarScore = ({
    score,
    sumStar = 5,
}: IProp) => {
    const fullCount = Math.floor(score);
    const emptyCount = fullCount >= sumStar ? 0 : Math.floor(sumStar - score);
    const halfCount = (score - fullCount) > 0 ? 1 : 0;

    return <Center gap="2px">
        {
            new Array(fullCount).fill(0).map((item, index) => {
                return <FullStar key={index} />
            })
        }
        {
            !!halfCount && <HalfStar />
        }
        {
            new Array(emptyCount).fill(0).map((item, index) => {
                return <EmptyStar key={index} />
            })
        }
    </Center>
}

export default StarScore;