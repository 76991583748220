import React from "react";
import * as styles from "./index.module.less";
import BlokContainer from "@/components/blok-container";
import customStyles from "@/assets/theme/customStyles";
import { Center, Flex, Text } from "@chakra-ui/react";
import CommonButton from "@/components/common-button";
import RatioMedia from "@/components/ratio-media";

const HeroBanner = () => {
  return (
    <BlokContainer maxW={customStyles.web_max_w}>
      <Center
        w="full"
        gap={{
          base: "24px",
          sm: "40px",
          lg: "64px",
          xl: "80px",
        }}
        flexDir={{
          base: "column",
          xl: "row",
        }}
      >
        <Flex
          flex={1}
          flexDir="column"
          gap={{
            base: "24px",
            sm: "40px",
            md: "48px",
            lg: "64px",
          }}
          alignItems={{
            base: "center",
            xl: "flex-start",
          }}
        >
          <Flex
            flexDir="column"
            gap="24px"
            textAlign={{
              base: "center",
              xl: "left",
            }}
          >
            <Text as="h1">Amplify the Value of Every Microsoft Teams Call</Text>
            <Text>
              Record meeting video, convert speech to text, and share important
              moments - have an efficient meeting like never before.
            </Text>
          </Flex>
          <Flex
            flexDir={{
              base: "column",
              sm: "row",
            }}
            gap={{
              base: "16px",
              sm: "24px",
            }}
            alignItems={{
              base: "flex-start",
            }}
          >
            <CommonButton
              gtmId="web_sign_up_cta_btn_platform_teams"
              id="product_teams_hero_cta"
              to="https://app.airgram.io/signup"
              content="Start free trial"
              bg="linear-gradient(90deg, #9C06FF 0%, #E85CFF 100%)"
              w={{
                base: "180px",
                lg: "200px",
              }}
              h={{
                base: "46px",
                lg: "48px",
              }}
            />
            <CommonButton
              id="product_teams_pricing"
              target="_self"
              to="/pricing"
              content="See pricing"
              bg="#ffffff"
              color="#9F2AEC"
              w={{
                base: "180px",
                lg: "200px",
              }}
              h={{
                base: "46px",
                lg: "48px",
              }}
              border="2px solid #9F2AEC"
            />
          </Flex>
        </Flex>
        <Flex
          maxW={{
            base: "480px",
            "2xl": "560px",
          }}
          w="full"
        >
          <RatioMedia
            media={{
              type: "image",
              src: "https://a.storyblok.com/f/167495/1240x930/827aaecb92/teams-page-banner.png",
              alt: "share meeting minutes using Airgram",
            }}
          />
        </Flex>
      </Center>
    </BlokContainer>
  );
};

export default HeroBanner;
