import CommonButton from "@/components/common-button";
import {
  Box,
  Center,
  Flex,
  Text,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import React from "react";

interface IFeatureIconTitle {
  icon: string;
  iconAlt: string;
  title: string;
}

const FeatureIconTitle: IFeatureIconTitle[] = [
  {
    icon: "https://a.storyblok.com/f/167495/x/0453f7f22b/video-recording.svg",
    iconAlt: "video recording icon",
    title: "Video recording",
  },
  {
    icon: "https://a.storyblok.com/f/167495/x/8b82a134e0/live-transcription.svg",
    iconAlt: "live transcription icon",
    title: "Live transcription",
  },
  {
    icon: "https://a.storyblok.com/f/167495/x/b329b45ff4/notes-collaboration.svg",
    iconAlt: "notes collaboration icon",
    title: "Notes collaboration",
  },
];

const FeatureIconTitleComponent = () => {
  return (
    <Center
      flexDir="row"
      flex="1"
      gap="24px"
      w="full"
      justifyContent="space-between"
      px="32px"
    >
      {FeatureIconTitle.map((item) => {
        return (
          <Center key={item.title} flexDir="column" gap="8px">
            <Image
              src={item.icon}
              alt={item.iconAlt}
              w={{
                base: "50px",
                sm: "60px",
              }}
            />
            <Text textAlign="center" as="h5" color="#8031CE">
              {item.title}
            </Text>
          </Center>
        );
      })}
    </Center>
  );
};

const TeamsBottomCta = () => {
  const layoutSize: "base" | "sm" | "md" = useBreakpointValue(
    {
      base: "base",
      sm: "sm",
      md: "md",
    },
    { fallback: "md" }
  );

  return (
    <Center
      w="full"
      p={{
        base: "32px 16px",
        md: "48px 40px",
        lg: "64px 80px",
        "2xl": "80px",
      }}
      boxShadow="0px 30px 60px rgba(220, 93, 231, 0.15)"
      borderRadius={{
        base: "16px",
        sm: "18px",
        xl: "25px",
      }}
      bg={
        layoutSize !== "md" &&
        "linear-gradient(116.93deg, #C64BF2 -3.88%, #9C6EE9 66.85%)"
      }
      className={layoutSize === "md" && "teams-bottom-cta"}
      flexDir="column"
      gap="32px"
    >
      <Center
        alignItems={{
          base: "center",
          md: "flex-start",
        }}
        w="full"
        flex="1"
        flexDir="column"
        gap={{
          base: "24px",
          sm: "30px",
        }}
      >
        <Text
          as="h2"
          color="#FFFFFF"
          textAlign={{
            base: "center",
            md: "left",
          }}
        >
          Get Empowered by Airgram Today
        </Text>
        <CommonButton
          gtmId="web_sign_up_cta_btn_platform_teams"
          id="product_teams_bottom_cta"
          to="https://app.airgram.io/signup"
          bg="#FFFFFF"
          content="Sign up for free"
          color="#9F2AEC"
        />
      </Center>
      {layoutSize === "sm" && <FeatureIconTitleComponent />}
    </Center>
  );
};

export default TeamsBottomCta;
