import React, { CSSProperties } from "react";
import * as styles from "./index.module.less";
import { Center, Image } from "@chakra-ui/react";
import { LOADING_IMAGE_URL } from "@/utils/constant";

interface IProps {
  media:
    | {
        type: "image";
        src: string;
        alt: string;
        objectFit?: "contain" | "cover";
      }
    | {
        type: "video";
        src: string;
        autoPlay?: boolean;
        loop?: boolean;
        muted?: boolean;
        objectFit?: "contain" | "cover";
      };
  ratio?: number;
  isLazyLoad?: boolean;
  className?: string;
  style?: CSSProperties;
  mediaClassName?: string;
  mediaStyle?: CSSProperties;
  [key: string]: any;
}

const RatioMedia = ({
  media,
  ratio = 3 / 4,
  isLazyLoad = false,
  className = "",
  style,
  mediaClassName = "",
  mediaStyle,
  ...others
}: IProps) => {
  return (
    <Center
      style={style}
      w="full"
      h="0"
      pt={`calc(100% * (${ratio}))`}
      pos="relative"
      className={className}
      {...others}
    >
      {media.type === "image" && (
        <Image
          pos="absolute"
          className={`${isLazyLoad && "lozad"} ${mediaClassName}`}
          style={mediaStyle}
          objectFit={isLazyLoad ? "cover" : media.objectFit ?? "fill"}
          data-src={media.src}
          data-object-fit={media.objectFit ?? "fill"}
          src={isLazyLoad ? LOADING_IMAGE_URL : media.src}
          alt={media.alt}
          w="100%"
          h="100%"
          top="0"
          left="0"
          minH="100%"
        />
      )}
      {media.type === "video" && (
        <video
          className={`${isLazyLoad && "lozad"} ${mediaClassName}`}
          style={Object.assign(
            {
              position: "absolute",
              objectFit: "cover",
              height: "100%",
              width: "100%",
              top: "0",
              left: "0",
            },
            mediaStyle,
            isLazyLoad
              ? {
                  objectFit: 'cover',
                }
              : {
                  objectFit: media.objectFit ?? 'fill',
                },
          )}
          autoPlay={media.autoPlay || true}
          muted={media.muted || true}
          loop={media.loop || true}
          poster={isLazyLoad && LOADING_IMAGE_URL}
          data-object-fit={media.objectFit ?? 'fill'}
        >
          <source
            data-src={media.src}
            src={isLazyLoad ? LOADING_IMAGE_URL : media.src}
            type="video/mp4"
          />
        </video>
      )}
    </Center>
  );
};
export default RatioMedia;
