import * as React from "react";
import Layout from "@layout/index";
import Header from "@components/head";
import {
  Text,
  List,
  ListItem,
  Flex,
  Center,
  VStack,
  Box,
  Grid,
  GridItem,
  Image,
} from "@chakra-ui/react";
import GraphicText from "@/layout/GraphicText";
import CommonButton from "@/components/common-button";
import QuoteText from "@/components/quote-text";
import BlokContainer from "@/components/blok-container";
import { ClientsReviews, featureList } from "@/content/product/Constants";
import TeamsClientReviewCard from "@/content/product/components/teams-client-review-card";
import TeamsFeatureCard from "@/content/product/components/teams-feature-card";
import TeamsBottomCta from "@/content/product/components/teams-bottom-cta";
import customStyles from "@/assets/theme/customStyles";
import HeroBanner from "@/modules/product/microsoft-teams/hero-banner";
import TCComment from "@/modules/product/microsoft-teams/tc-comment";
import FeatureDesc from "@/modules/product/microsoft-teams/feature-desc";
import MoreFeatures from "@/modules/product/microsoft-teams/more-features";
import { HOST_URL } from "@/utils/constant";

export const Head = () => {
  return (
    <Header
      ogUrl={`${HOST_URL}/product/microsoft-teams`}
      cover="https://a.storyblok.com/f/167495/1240x930/827aaecb92/teams-page-banner.png"
      title="Airgram | Record Any Teams Meeting with Live Transcription"
      description="Record a Microsoft Teams meeting without permission. Automatically capture meeting notes that are editable and shareable. Start for free with Airgram!"
    />
  );
};

const reviewPlace = ["flex-start", "center", "flex-end"];

const IndexPage = () => {
  return (
    <Layout
      pt="0px"
      px="0px"
      margin="0px"
      minW="100%"
      isNewStyle
      navMaxW={customStyles.web_max_w}
    >
      <HeroBanner />
      <TCComment />
      <VStack>
        {/* picture & content */}
        <FeatureDesc />
        {/* More is possible with Airgram */}
        <MoreFeatures />
        {/* Meet Our Happy Clients */}
        <BlokContainer
          blockTitle="Meet Our Happy Clients"
          maxW={customStyles.web_max_w}
        >
          <Grid
            mt={{
              base: "36px",
              sm: "48px",
              md: "60px",
            }}
            gridTemplateColumns={{
              base: "1fr",
              xl: "1fr 1fr 1fr",
            }}
            gap={{
              base: "69px",
              sm: "90px",
              md: "96px",
              lg: "100px",
              xl: "40px",
            }}
            h={{
              base: "auto",
              xl: "760px",
              "2xl": "738px",
            }}
          >
            {ClientsReviews.map((item, index) => {
              return (
                <GridItem key={item.title} alignSelf={reviewPlace[index % 3]}>
                  <TeamsClientReviewCard {...item} />
                </GridItem>
              );
            })}
          </Grid>
        </BlokContainer>
        {/* cta */}
        <BlokContainer pt="0px" maxW={customStyles.web_max_w}>
          <Center w="full">
            <TeamsBottomCta />
          </Center>
        </BlokContainer>
      </VStack>
    </Layout>
  );
};

export default IndexPage;
