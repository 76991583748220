export const featureList: IFeature[] = [
    {
        icon: "https://a.storyblok.com/f/167495/x/72a3f651c3/meeting-agenda.svg",
        iconAlt: "meeting agenda icon",
        title: "Create agenda",
        detailImage: "https://a.storyblok.com/f/167495/879x405/880449b390/1-create-agenda.png",
        detailImageAlt: "",
    },
    {
        icon: "https://a.storyblok.com/f/167495/x/94b9a80f8f/auto-join-meeting.svg",
        iconAlt: "auto-join meeting icon",
        title: "Auto join meetings",
        detailImage: "https://a.storyblok.com/f/167495/1010x348/6a704f0722/2-auto-join-meetings.png",
        detailImageAlt: "",
    },
    {
        icon: "https://a.storyblok.com/f/167495/x/0150a776de/keywords-extraction.svg",
        iconAlt: "keywords extraction icon",
        title: "Keywords extraction",
        detailImage: "https://a.storyblok.com/f/167495/975x408/c88f062e35/3-keywords-extraction.png",
        detailImageAlt: "",
    },
    {
        icon: "https://a.storyblok.com/f/167495/x/58e09072f8/quick-search.svg",
        iconAlt: "smart search icon",
        title: "Quick search",
        detailImage: "https://a.storyblok.com/f/167495/1009x476/58ca64b724/4-quick-search.png",
        detailImageAlt: "",
    },
]

export const ClientsReviews: IClientsReview[] = [
    {
        avatarUrl: "https://a.storyblok.com/f/167495/170x170/d28e402b30/jason-lee.png",
        avatarAlt: "profile image Jason",
        username: "Jason Lee",
        star: 5,
        title: "“A super productive online meeting support tool.”",
        desc: "Airgram will provide you with a productive online meeting experience, both during and after the meeting. For some important meetings, it could be an evidence recorder both on video and sound."
    },
    {
        avatarUrl: "https://a.storyblok.com/f/167495/171x170/84c06a027e/alex.png",
        avatarAlt: "profile image Alex",
        username: "Alex",
        star: 4.5,
        title: "“Quite good tool to keep meeting notes.”",
        desc: "We use Airgram during customer calls. I like the fact that Airgram allows saving both recordings of meetings and also transcripts. It is also very helpful to cut some clips to share it with others."
    },
    {
        avatarUrl: "https://a.storyblok.com/f/167495/170x170/865e0e5ca8/natallia-a.png",
        avatarAlt: "profile image Natallia",
        username: "Natallia A.",
        star: 5,
        title: "“A great time saver.”",
        desc: "I love that this tool is able to automatically join Teams meetings on your behalf based on items in your calendar – and record those meetings on your behalf as well as transcribe them."
    }
]