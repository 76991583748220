import React, { CSSProperties } from "react";
import * as styles from "./index.module.less";
import { Box, Center, Flex, Text } from "@chakra-ui/react";

interface IProps {
  media: any;
  content: any;
  layout?: "media-content" | "content-media";
  className?: string;
  mediaClass?: string;
  contentClass?: string;
  title?: string;
  mediaStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  mediaMaxW?: any;
  contentMaxW?: any;
  [key: string]: any;
}

const SymmetricalLayout = ({
  media,
  content,
  title = "",
  layout = "media-content",
  className = "",
  mediaClass = "",
  contentClass = "",
  mediaStyle,
  contentStyle,
  mediaMaxW = { base: "480px", lg: "560px" },
  contentMaxW = { base: "480px", lg: "560px" },
  ...others
}: IProps) => {
  return (
    <Center
      w="full"
      justifyContent="space-between"
      className={className}
      flexDir={
        layout === "content-media"
          ? {
              base: "column",
              lg: "row-reverse",
            }
          : {
              base: "column",
              lg: "row",
            }
      }
      gap={{
        base: "24px",
        sm: "32px",
        lg: "80px",
      }}
      {...others}
    >
      <Flex
        flex={1}
        w="full"
        className={mediaClass}
        style={mediaStyle}
        maxW={mediaMaxW}
      >
        {media}
      </Flex>
      <Flex
        flexDir="column"
        flex={1}
        w="full"
        gap={{
          base: "12px",
          sm: "16px",
          lg: "24px",
        }}
        className={contentClass}
        style={contentStyle}
        maxW={contentMaxW}
      >
        {title !== "" && <Text as="h2">{title}</Text>}
        <Box w="full">{content}</Box>
      </Flex>
    </Center>
  );
};
export default SymmetricalLayout;
