import React from "react";
import * as styles from "./index.module.less";
import BlokContainer from "@/components/blok-container";
import customStyles from "@/assets/theme/customStyles";
import { Grid, GridItem } from "@chakra-ui/react";
import { featureList } from "@/content/product/Constants";
import TeamsFeatureCard from "@/content/product/components/teams-feature-card";

const MoreFeatures = () => {
  return (
    <BlokContainer
      blockBg="#FAFBFD"
      blockTitle="More is Possible with Airgram"
      maxW={customStyles.web_max_w}
    >
      <Grid
        w="full"
        gridTemplateColumns={{
          base: "1fr",
          sm: "1fr 1fr",
          xl: "1fr 1fr 1fr 1fr",
        }}
        gap={{
          base: "20px",
          lg: "40px",
        }}
      >
        {featureList.map((featureItem, index) => {
          return (
            <GridItem w="full" h="full" key={index}>
              <TeamsFeatureCard {...featureItem} />
            </GridItem>
          );
        })}
      </Grid>
    </BlokContainer>
  );
};
export default MoreFeatures;
