import StarScore from "@/components/star-score";
import { isCanSeeInPlace } from "@/utils";
import { Center, Text, Image } from "@chakra-ui/react";
import { snakeCase } from "lodash-es";
import React, { useEffect, useState } from "react";

const TeamsClientReviewCard = ({
  avatarUrl,
  avatarAlt,
  username,
  star,
  title,
  desc,
}: IClientsReview) => {
  const id = `teams_${snakeCase(username)}_card`;
  const [isShowed, setIsShowed] = useState(false);

  const handleScroll = () => {
    const isCanSee = isCanSeeInPlace(id, "bottom");
    if (isCanSee && !isShowed) {
      setIsShowed(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Center
      id={id}
      transition="all .3s .25s ease-out"
      transform={isShowed ? "translateY(0px)" : "translateY(300px)"}
      opacity={isShowed ? "1" : "0"}
      pos="relative"
      flexDir="column"
      p="40px"
      pt={{
        base: "90px",
        sm: "100px",
        md: "110px",
        lg: "120px",
        xl: "60px",
      }}
      borderRadius={{
        base: "16px",
        sm: "18px",
        lg: "20px",
      }}
      boxShadow="0px 4px 75px rgba(155, 183, 203, 0.25)"
      gap="24px"
    >
      <Center
        pos="absolute"
        top={{
          base: "-36px",
          md: "-40px",
          lg: "-56px",
        }}
        gap="10px"
        flexDir={{
          base: "column",
          xl: "row",
        }}
      >
        <Image
          w={{
            base: "65px",
            md: "75px",
            lg: "85px",
          }}
          src={avatarUrl}
          alt={avatarAlt}
        />
        <Text textAlign="center" as="h4">
          {username}
        </Text>
      </Center>
      <StarScore score={star} />
      <Text as="h4" textAlign="center">
        {title}
      </Text>
      <Text>{desc}</Text>
    </Center>
  );
};

export default TeamsClientReviewCard;
