import { Flex, Text, List, ListItem } from "@chakra-ui/react";
import React from "react";

export const FEATURE_DESC_LIST = [
  {
    image: {
      src: "https://a.storyblok.com/f/167495/1200x900/905f87fb5d/teams-page-1.png",
      alt: "start recording a Teams meeting",
    },
    title: "Record a Teams meeting without permission",
    content: (
      <Flex flexDir="column" gap="12px">
        <Text>
          Record the Microsoft Teams meetings as a host or participant with a
          simple click; no permission is required.
        </Text>

        <List listStyleType="disc" pl="2em">
          <ListItem>
            Capture video, audio, and screen sharing activity in high quality.
          </ListItem>
          <ListItem>
            The recording is immediately available for replay and download when
            the meeting ends.
          </ListItem>
        </List>
      </Flex>
    ),
  },
  {
    image: {
      src: "https://a.storyblok.com/f/167495/1200x900/2678fcbc45/teams-page-2.png",
      alt: "Teams meeting transcription",
    },
    title: "Get live transcripts that are editable",
    content: (
      <Flex flexDir="column" gap="12px">
        <Text>
          The transcript appears alongside the meeting video in real time with a
          time stamp, making your meetings more inclusive and productive for
          people who are hard-of-hearing or in noisy places.
        </Text>
        <List listStyleType="disc" pl="2em">
          <ListItem>
            Intelligent voice recognition to differentiate speakers.
          </ListItem>
          <ListItem>
            Eight transcription language options are available.
          </ListItem>
          <ListItem>
            Revisit the transcript, make edits, and highlight key texts.
          </ListItem>
        </List>
      </Flex>
    ),
  },
  {
    image: {
      src: "https://a.storyblok.com/f/167495/1200x900/f57d6085bb/teams-page-3.png",
      alt: "collaborate on taking meeting notes",
    },
    title: "Collaborate on note-taking with all",
    content: (
      <Flex flexDir="column" gap="12px">
        <Text>
          All participants generate co-created notes on the integrated Notepad
          as the meeting unfolds. Collaborate like they are in the room.
        </Text>

        <List listStyleType="disc" pl="2em">
          <ListItem>
            Contribute information in real-time or across time zones.
          </ListItem>
          <ListItem>
            Rich-text editor to add images, lists, and talking points easily.
          </ListItem>
          <ListItem>Assign tasks to a specific person with due dates.</ListItem>
        </List>
      </Flex>
    ),
  },
  {
    image: {
      src: "https://a.storyblok.com/f/167495/1200x900/65a75656a3/teams-page-4.png",
      alt: "share meeting minutes using Airgram",
    },
    title: "Share anywhere for team alignment",
    content: (
      <Flex flexDir="column" gap="12px">
        <Text>
          What’s the point of recording without sharing? Leverage Airgram’s
          powerful sharing to keep your team aligned from anywhere.
        </Text>

        <List listStyleType="disc" pl="2em">
          <ListItem>
            Share the meeting minutes with anyone via link or email.
          </ListItem>
          <ListItem>
            Create a snippet from the transcript to share key meeting insights.
          </ListItem>
          <ListItem>
            Export meeting notes and transcript to Slack, Notion, Google Docs,
            and Microsoft Word.
          </ListItem>
        </List>
      </Flex>
    ),
  },
];
