import React from "react";
import * as styles from "./index.module.less";
import { Box, Center, Image, Text } from "@chakra-ui/react";
import QuoteText from "@/components/quote-text";
import customStyles from "@/assets/theme/customStyles";
import { ReactComponent as QuoteSvg } from "@/assets/images/CommonQuote.svg";

const TCComment = () => {
  return (
    <Center
      w="full"
      px={{
        base: "24px",
        sm: "40px",
        lg: "112px",
        xl: "120px",
        "2xl": "160px",
      }}
      py={{
        base: "24px",
        sm: "32px",
      }}
      bg="#FAFBFD"
      zIndex="-2"
    >
      <Center
        maxW={customStyles.web_max_w}
        gap={{
          base: "24px",
          sm: "40px",
        }}
      >
        <Image
          w={{
            base: "48px",
            sm: "64px",
            lg: "90px",
          }}
          src="https://a.storyblok.com/f/167495/x/9784639238/tc-logo.svg"
          alt="tc logo"
        />
        <Box pos="relative">
          <QuoteSvg
            width="32px"
            height="24px"
            fill="#EEEEEE"
            style={{
              position: "absolute",
              zIndex: "1",
              transform: "translate(-50%, -50%)",
            }}
          />
          <Text
            fontWeight="500"
            fontSize={{ base: "16px", lg: "18px" }}
            zIndex="2"
            pos="relative"
          >
            Airgram transcribes Microsoft Teams calls into sharable and editable
            text and helps people keep their meetings on track.
          </Text>
        </Box>
      </Center>
    </Center>
  );
};
export default TCComment;
