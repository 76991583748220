import React from "react";
import * as styles from "./index.module.less";
import BlokContainer from "@/components/blok-container";
import customStyles from "@/assets/theme/customStyles";
import { Center, Image } from "@chakra-ui/react";
import { FEATURE_DESC_LIST } from "../constants";
import SymmetricalLayout from "@/components/symmetrical-layout";
import RatioMedia from "@/components/ratio-media";

const FeatureDesc = () => {
  return (
    <BlokContainer maxW={customStyles.web_max_w}>
      <Center
        w="full"
        flexDir="column"
        gap={{
          base: "48px",
          sm: "64px",
          xl: "80px",
          "2xl": "100px",
        }}
      >
        {FEATURE_DESC_LIST.map((item, index) => {
          return (
            <SymmetricalLayout
              key={item.title}
              title={item.title}
              media={
                <RatioMedia
                  media={{
                    type: "image",
                    src: item.image.src,
                    alt: item.image.alt,
                  }}
                  isLazyLoad={true}
                />
              }
              content={item.content}
              layout={index % 2 ? "content-media" : "media-content"}
            />
          );
        })}
      </Center>
    </BlokContainer>
  );
};
export default FeatureDesc;
