import {
  Center,
  Text,
  Image,
  Flex,
  useBreakpointValue,
  Box,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";

const TeamsFeatureCard = ({
  icon,
  iconAlt,
  title,
  detailImage,
  detailImageAlt,
}: IFeature) => {
  return (
    <Center
      w="full"
      h="full"
      pos="relative"
      bg="#FFFFFF"
      p={{
        base: "12px 20px",
        sm: "20px",
        "2xl": "24px",
      }}
      borderRadius={{
        base: "10px",
        sm: "12px",
        lg: "18px",
        "2xl": "20px",
      }}
      alignItems="flex-start"
      overflow="hidden"
      boxShadow="10px 18px 40px rgba(168, 166, 165, 0.08)"
    >
      <Center gap="16px" alignItems="center" w="full">
        <Image
          w={{
            base: "40px",
            lg: "48px",
            "2xl": "60px",
          }}
          h={{
            base: "40px",
            lg: "48px",
            "2xl": "60px",
          }}
          loading="lazy"
          src={icon}
          alt={iconAlt}
          objectFit="contain"
        />
        <Text as="h5">{title}</Text>
      </Center>
    </Center>
  );
};

export default TeamsFeatureCard;
